<template>
  <div class="is-scroll-target">
    <v-text-field v-model="term" class="mt-3" dense outlined hide-details="auto" label="Rechercher un groupe" />
    <q-infinite-scroll ref="is" :offset="500" scroll-target=".is-scroll-target" @load="onLoad">
      <template v-if="items.length">
        <team-list-item v-for="item in items" :key="item" :team-uid="item" :active="value === item" selectable @click="$emit('input', item)" />
      </template>
      <template v-else-if="!loading">
        Pas de résultats
      </template>
      <template v-slot:loading>
        <skeleton-list :small="items.length > 0" />
      </template>
    </q-infinite-scroll>
  </div>
</template>

<script>
  import * as Teams from '@/modules/teams/api'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import {debounce} from 'throttle-debounce'
  import {QInfiniteScroll} from 'quasar'
  import {Space, Team} from '@/models'
  import TeamListItem from '@/modules/teams/components/TeamListItem'
  import {column} from '@/utils/array'

  export default {
    name: 'TeamsListSelect',
    components: {TeamListItem, SkeletonList, QInfiniteScroll},
    props: {
      value: String
    },
    data() {
      return {
        loading: false,
        items: [],
        previousTerm: '',
        term: ''
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      team() {
        return uid => Team.get(uid)
      }
    },
    watch: {
      term: debounce(300, async function (v) {
        let term = v.length >= 3 ? v : ''
        if (this.previousTerm !== term) {
          this.previousTerm = term
          this.items = []
          this.$refs.is.reset()
          this.$refs.is.resume()
        }
      })
    },
    methods: {
      async onLoad(index, done) {
        try {
          this.loading = true
          let searchTerm = this.term.length >= 3 ? this.term : null
          let {list, total} = (await Teams.list(this.space.uid, ['patient', 'total_members'], {
            status: 'active',
            member: this.$auth.user.sub,
            name: searchTerm
          }, index, 10)).data

          let currentTerm = this.term.length >= 3 ? this.term : null
          if (currentTerm !== searchTerm) {
            done()
            return
          }

          Team.save(list)
          this.items = this.items.concat(column(list, 'uid'))
          done(this.items.length >= total)
        } catch (e) {
          this.$handleApiError(e)
          done(true)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
